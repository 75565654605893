import React from "react";
import {useTranslation} from "react-i18next";
import Button from "../components/01_atoms/Button/Button";
import {StructuredText} from 'react-datocms';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {Helmet} from "react-helmet"
import {graphql} from 'gatsby';

import * as pages from '../components/03_organisms/Pages/pages.module.scss';

import * as styles from './styles/home.module.scss';
import Container from "../components/03_organisms/Container/Container";
import WorkItem from "../components/02_molecules/WorkItem/WorkItem";
import Slider from "../components/03_organisms/Slider/Slider";

import * as headerStyles from "../components/03_organisms/Header/styles/header.module.scss";
import {PageTransition} from "../components/03_organisms/PageTransition/PageTransition";

import {motion} from 'framer-motion';
import FadeInUp from "../components/01_atoms/FadeInUp/FadeInUp";
import Wrapper from '../components/03_organisms/Wrapper/Wrapper';
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";


const Home = ({data, pageContext, ...props}) => {

    // read language
    const {t} = useTranslation();
    const homepageData = data.datoCmsHome;


    // render html
    return (
        <Wrapper>
			<HeadInject />
            <PageTransition>
                <Helmet bodyAttributes={{class: `${pages.home} ${headerStyles.inverted}`}}/>
                <HelmetDatoCms seo={data.datoCmsHome.seoMetaTags}/>

                <section className={styles.intro}>
                    <div className={styles.inner}>
                        <Container>
                            <FadeInUp delay=".5">
                                <div className={styles.intro__heading}>
                                    <StructuredText data={homepageData.intro}></StructuredText>
                                </div>
                            </FadeInUp>
                            <FadeInUp delay=".75">
                                <Button inverted to="/about">{t('homepage_intro_cta')}</Button>
                            </FadeInUp>
                        </Container>

                    </div>
                    <motion.div className={styles.video} initial={{height: 0}} animate={{height: '100%'}} transition={{duration: .5, delay: 0, ease: [0.43, 0.13, 0.23, 0.96]}}>
                        {homepageData.hero[0].heroVideo ? <video playsInline muted loop autoPlay>
                            <source src={homepageData.hero[0].heroVideo.url} type="video/mp4"/>
                        </video> : null}
                    </motion.div>
                </section>


                <Slider title="Unexpected works">
                    {homepageData.showcasedWork.map((work, index) => (
                        <WorkItem
                            key={index}
                            title={work.title}
                            client={work.client.name}
                            link={'/work/' + work.client.slug + '/' + work.slug}
                            image={work.hero[0].heroImage}
                            alt={work.hero[0].heroImage.alt}
                        />
                    ))}
                </Slider>

            </PageTransition>
        </Wrapper>
    )
}

export default Home;

export const query = graphql`
query HomeQuery {
	datoCmsHome {
		seoMetaTags {
			...GatsbyDatoCmsSeoMetaTags
		}
		title
		slug
		intro{
			value
		}
		hero {
			heroImage {
			  gatsbyImageData
			  alt
			  url
			}
			heroVideo {
			  url
			  width
			  height
			}
		}
		showcasedWork {
		  title
		  slug
		  client {
			name
			slug
		  }
		  hero {
			heroImage {
			  format
			  url
			  gatsbyImageData
			  alt
			}
			heroVideo {
			  url
			  width
			  height
			}
			seoMetaTags{
				...GatsbyDatoCmsSeoMetaTags
			}
		  }
		}
		
	}
  }
  
`
